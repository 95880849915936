/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : '',
        link : '/example'
    },
    {
        id   : 'workspace',
        title: 'Workspaces',
        type : 'basic',
        icon : '',
        link : '/workspaces'
    },
    {
        id   : 'invoices',
        title: 'Invoices',
        type : 'basic',
        icon : '',
        link : '/invoices'
    },
    // {
    //     id   : 'support',
    //     title: 'Support',
    //     type : 'basic',
    //     icon : '',
    //     link : '/example'
    // },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        icon : '',
        link : '/settings'
    }
];
