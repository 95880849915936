import { Component } from '@angular/core';
import {  MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-work-space-button',
  standalone: true,
  imports: [RouterLink,MatIconModule],
  templateUrl: './work-space-button.component.html',
  styleUrl: './work-space-button.component.scss'
})
export class WorkSpaceButtonComponent {

}
